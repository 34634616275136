import * as React from 'react';
import { FormikFieldWrapper } from 'app/styleguide/components/Formik';

const DesignRequestForm = () => (
  <FormikFieldWrapper
    data-cy="designRequestComments"
    name="designRequest.comments"
    rows={5}
    componentType="textarea"
    labelComponent="Project Details"
  />
);

export default DesignRequestForm;
