import { Upload } from 'api/orders/types';
import { Address } from '../Account/Addresses/types';

export interface Option {
  key: string;
  label: string;
}

export interface UserInput {
  name: string;
  email: string;
  phoneNumber: string;
}

export const emptyUserInput = {
  name: '',
  email: '',
  phoneNumber: '',
};

export interface QuoteRequest {
  product: string;
  dimension: string;
  paperType: string;
  orientation: string;
  documentCount: string;
  pagesPerDocument: string;
  sides: string;
  color: string;
  comments: string;
  address: Address;
}

// typescript false +ve bug. There is no shadowing
// eslint-disable-next-line no-shadow
export enum FormType {
  FILE_CHECK_REQUEST = 'fileCheckRequest',
  QUOTE_REQUEST = 'quoteRequest',
  GENERAL_REQUEST = 'generalRequest',
  DESIGN_REQUEST = 'designRequest',
  BOXES_REQUEST = 'boxesRequest',
}

export interface BaseFormType {
  userInput: UserInput;
  formType: FormType;
}

export interface RecaptchaFormType extends BaseFormType {
  recaptchaResponse?: string;
}

export interface QuoteRequestFormType extends RecaptchaFormType {
  quoteRequest: QuoteRequest;
}

export const emptyQuoteRequest: QuoteRequest = {
  product: '',
  dimension: '',
  paperType: '',
  orientation: '',
  documentCount: '',
  pagesPerDocument: '',
  sides: '',
  color: '',
  comments: '',
  address: null,
};

export interface GeneralRequest {
  comments: string;
}

export interface GeneralRequestFormType extends RecaptchaFormType {
  generalRequest: GeneralRequest;
}

export const emptyGeneralRequest: GeneralRequest = { comments: '' };

export interface FileCheckRequest {
  product: string;
  dimension: string;
  fullBleed: string;
  comments: string;
  attachments: Upload[];
}

export interface FileCheckRequestFormType extends RecaptchaFormType {
  fileCheckRequest: FileCheckRequest;
}

export const emptyFileCheckRequest: FileCheckRequest = {
  product: '',
  dimension: '',
  fullBleed: '',
  comments: '',
  attachments: [],
};

export interface DesignRequest {
  comments: string;
}

export interface DesignRequestFormType extends RecaptchaFormType {
  designRequest: DesignRequest;
}

export const emptyDesignRequest: DesignRequest = { comments: '' };

// This is to support the new Design Service page
// Todo: Reconcile with DesignRequest
export interface DesignServiceRequest {
  projectName: string;
  deadline: string;
  product: string;
  numPages: number;
  width: number;
  height: number;
  description: string;
  attachments: Upload[];
}

export interface DesignServiceRequestFormType {
  userInput: UserInput;
  designServiceRequest: DesignServiceRequest;
}

export const emptyDesignServiceRequest: DesignServiceRequest = {
  projectName: '',
  deadline: '',
  product: '',
  numPages: null,
  width: null,
  height: null,
  description: '',
  attachments: [],
};

export const emptyDesignServiceRequestForm: DesignServiceRequestFormType = {
  userInput: emptyUserInput,
  designServiceRequest: emptyDesignServiceRequest,
};

export interface BoxesRequest {
  comments: string;
  product: string;
  dimension: string;
  paperType: string;
  sides: string;
  documentCount: string;
}

export interface BoxesRequestFormType extends RecaptchaFormType {
  boxesRequest: BoxesRequest;
}

export const emptyBoxesRequest: BoxesRequest = {
  product: '',
  dimension: '',
  paperType: '',
  documentCount: '',
  sides: '',
  comments: '',
};

export type UnionFormType =
  | FileCheckRequestFormType
  | GeneralRequestFormType
  | QuoteRequestFormType
  | DesignRequestFormType
  | BoxesRequestFormType;
