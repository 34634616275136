import * as React from 'react';
import { boxType, boxesDimensions } from 'bundles/App/pages/ContactUsPage/formOptions';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import { useFormikContext } from 'formik';
import { BoxesRequestFormType } from 'bundles/App/pages/ContactUsPage/types';
import Label from 'styleguide/components/Formik/Label/Label';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import { FormikFieldWrapper } from 'app/styleguide/components/Formik';

const BoxesRequestForm = () => {
  const { values } = useFormikContext<BoxesRequestFormType>();

  return values.boxesRequest ? (
    <>
      <FormikFieldWrapper
        data-cy="boxesRequest.product"
        name="boxesRequest.product"
        componentType="combobox"
        options={boxType}
        labelComponent="Box Type"
        inPlaceError
        creatable
      />
      <FormikFieldWrapper
        data-cy="boxesRequest.dimension"
        name="boxesRequest.dimension"
        componentType="combobox"
        options={boxesDimensions}
        labelComponent="Dimension"
        inPlaceError
        creatable
      />
      <FieldWrapper
        className="mt-1"
        Label={<Label placement="left">Corrugated Material</Label>}
        Input={
          <RadioTabs name="boxesRequest.paperType">
            <FormikFieldWrapper
              dataCy="boxesRequest.paperType.white"
              name="boxesRequest.paperType"
              value="White Corrugated Cardboard"
              labelComponent="White Corrugated Cardboard"
              componentType="radio"
            />
            <FormikFieldWrapper
              dataCy="boxesRequest.paperType.brown"
              name="boxesRequest.paperType"
              value="Kraft (Brown) Corrugated Cardboard"
              labelComponent="Kraft (Brown) Corrugated Cardboard"
              componentType="radio"
            />
          </RadioTabs>
        }
      />
      <FieldWrapper
        className="!mt-4"
        Label={<Label placement="left">Number of Sides</Label>}
        Input={
          <RadioTabs name="boxesRequest.sides">
            <FormikFieldWrapper
              dataCy="boxesRequest.sides.single"
              name="boxesRequest.sides"
              value="Single Sided"
              labelComponent="Single Sided"
              componentType="radio"
            />
            <FormikFieldWrapper
              dataCy="boxesRequest.sides.double"
              name="boxesRequest.sides"
              value="Double Sided"
              labelComponent="Double Sided"
              componentType="radio"
            />
          </RadioTabs>
        }
      />
      <FieldWrapper
        className="!mt-4"
        Input={
          <FormikFieldWrapper
            data-cy="boxesRequest.documentCount"
            name="boxesRequest.documentCount"
            componentType="input"
            labelComponent="Number of Boxes"
          />
        }
      />
      <FieldWrapper
        className="!mt-4"
        Input={
          <FormikFieldWrapper
            data-cy="boxesRequest.comments"
            name="boxesRequest.comments"
            rows={5}
            componentType="textarea"
            labelComponent="Comments"
          />
        }
      />
    </>
  ) : null;
};

export default BoxesRequestForm;
