import * as React from 'react';
import { array, number, object, string } from 'yup';
import GeneralRequestForm from './GeneralRequestForm';
import QuoteRequestForm from './QuoteRequestForm';
import FileCheckRequestForm from './FileCheckRequestForm';
import {
  emptyBoxesRequest,
  emptyDesignRequest,
  emptyFileCheckRequest,
  emptyGeneralRequest,
  emptyQuoteRequest,
  emptyUserInput,
  Option,
  FormType,
  UnionFormType,
} from './types';
import { Status } from 'libs/utils/api/types';
import { submitForm } from 'api/contactUs';
import Footer from 'styleguide/components/Footer/Footer';
import Button from 'styleguide/components/Button/Button';
import { Redirect } from 'react-router-dom';
import Captcha from 'bundles/App/pages/ContactUsPage/Captcha';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import Meta from 'styleguide/components/Meta/Meta';
import { formSubmit } from 'api/gtm';
import IconPhoneFilled from 'styleguide/icons/IconPhoneFilled';
import A from 'styleguide/components/Links/A';
import { phoneRegex } from 'utils/schema';
import { Form, Formik } from 'formik';
import DesignRequestForm from 'bundles/App/pages/ContactUsPage/designRequestForm';
import BoxesRequestForm from 'bundles/App/pages/ContactUsPage/boxesRequestForm';
import { Upload } from 'api/orders/types';
import InPlaceMessage from 'app/styleguide/components/InPlaceMessage/InPlaceMessage';
import { contactUsPath } from 'bundles/App/routes';
import { useLocation } from 'react-router';
import { camelCase } from 'lodash-es';
import { FormikFieldWrapper } from 'app/styleguide/components/Formik';

const options: Option[] = [
  { key: 'generalRequest', label: 'General request' },
  { key: 'quoteRequest', label: 'Quote request' },
  { key: 'fileCheckRequest', label: 'File check request' },
  { key: 'designRequest', label: 'Design service request' },
  { key: 'boxesRequest', label: 'Shipping boxes request' },
];

const userInputSchema = object().shape({
  email: string().email('Please enter a valid email.').required('Email is required.'),
  phoneNumber: string()
    .trim('Phone number cannot begin or end with spaces.')
    .required('Phone number is required.')
    .matches(phoneRegex, 'Phone number is not valid.'),
  name: string().required('Name is required.'),
});

const recaptchaErrorMessage = 'Recaptcha is required.';
const generalRequestFormSchema = object().shape({
  formType: string().oneOf(Object.values(FormType)),
  userInput: userInputSchema,
  generalRequest: object().shape({
    comments: string(),
  }),
  recaptchaResponse: string().required(recaptchaErrorMessage),
});

const designRequestFormSchema = object().shape({
  formType: string().oneOf(Object.values(FormType)),
  userInput: userInputSchema,
  designRequest: object().shape({
    comments: string(),
  }),
  recaptchaResponse: string().required(recaptchaErrorMessage),
});

const fileCheckRequestFormSchema = object().shape({
  formType: string().oneOf(Object.values(FormType)),
  userInput: userInputSchema,
  fileCheckRequest: object().shape({
    product: string(),
    dimension: string(),
    bleeds: string(),
    comments: string(),
    attachments: array().of(
      object<Upload>().shape({
        id: number(),
        name: string(),
        url: string(),
      }),
    ),
  }),
  recaptchaResponse: string().required(recaptchaErrorMessage),
});

const boxesRequestFormSchema = object().shape({
  formType: string().oneOf(Object.values(FormType)),
  userInput: userInputSchema,
  boxesRequest: object().shape({
    product: string(),
    dimension: string(),
    paperType: string(),
    sides: string(),
    documentCount: string(),
    comments: string(),
  }),
  recaptchaResponse: string().required(recaptchaErrorMessage),
});

const quoteRequestFormSchema = object().shape({
  formType: string().oneOf(Object.values(FormType)),
  userInput: userInputSchema,
  quoteRequest: object().shape({
    product: string(),
    dimension: string(),
    paperType: string(),
    orientation: string(),
    documentCount: string(),
    pagesPerDocument: string(),
    sides: string(),
    color: string(),
    address: object()
      .shape({
        address1: string(),
        address2: string(),
        city: string(),
        stateId: number().nullable(),
        countryId: number(),
        zipcode: string(),
      })
      .nullable(),
    comments: string(),
  }),
  recaptchaResponse: string().required(recaptchaErrorMessage),
});

const ContactUsPage = () => {
  const [formType, setFormType] = React.useState<string>(options[0].key);
  const [redirect, setRedirect] = React.useState(false);
  const [form, setForm] = React.useState(<GeneralRequestForm />);
  const [formSchema, setFormSchema] = React.useState<
    | typeof fileCheckRequestFormSchema
    | typeof quoteRequestFormSchema
    | typeof generalRequestFormSchema
    | typeof designRequestFormSchema
    | typeof boxesRequestFormSchema
  >(generalRequestFormSchema);
  const [initialValues, setInitialValues] = React.useState<UnionFormType>({
    formType: FormType.GENERAL_REQUEST,
    userInput: emptyUserInput,
    generalRequest: emptyGeneralRequest,
  });
  const location = useLocation();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const subject = queryParams.get('subject');
    if (subject) {
      setFormType(camelCase(subject));
    }
  }, []);

  React.useEffect(() => {
    if (formType === 'fileCheckRequest') {
      setForm(<FileCheckRequestForm />);
      setFormSchema(fileCheckRequestFormSchema);
      setInitialValues({
        formType: FormType.FILE_CHECK_REQUEST,
        userInput: emptyUserInput,
        fileCheckRequest: emptyFileCheckRequest,
        recaptchaResponse: '',
      });
    } else if (formType === 'quoteRequest') {
      setForm(<QuoteRequestForm />);
      setFormSchema(quoteRequestFormSchema);
      setInitialValues({
        formType: FormType.QUOTE_REQUEST,
        userInput: emptyUserInput,
        quoteRequest: emptyQuoteRequest,
        recaptchaResponse: '',
      });
    } else if (formType === 'generalRequest') {
      setForm(<GeneralRequestForm />);
      setFormSchema(generalRequestFormSchema);
      setInitialValues({
        formType: FormType.GENERAL_REQUEST,
        userInput: emptyUserInput,
        generalRequest: emptyGeneralRequest,
        recaptchaResponse: '',
      });
    } else if (formType === 'designRequest') {
      setForm(<DesignRequestForm />);
      setFormSchema(designRequestFormSchema);
      setInitialValues({
        formType: FormType.DESIGN_REQUEST,
        userInput: emptyUserInput,
        designRequest: emptyDesignRequest,
        recaptchaResponse: '',
      });
    } else if (formType === 'boxesRequest') {
      setForm(<BoxesRequestForm />);
      setFormSchema(boxesRequestFormSchema);
      setInitialValues({
        formType: FormType.BOXES_REQUEST,
        userInput: emptyUserInput,
        boxesRequest: emptyBoxesRequest,
        recaptchaResponse: '',
      });
    }
  }, [formType]);

  const onSubmit = values => {
    formSubmit(formType).then(() => {
      submitForm({ ...values, formType }).then(res => {
        if (res.status === Status.Ok) {
          setRedirect(true);
        }
      });
    });
  };

  return (
    <>
      <Meta
        title="Printivity Custom Printing Services Contact | Printivity"
        description={
          'Need an online printing service that can help with your print project?  Printivity is ' +
          'here to help! Call or email us for help or a quote. Contact us today! '
        }
        canonical="https://www.printivity.com/contact-us"
        keywords="printivity, contact, quote, phone number, email, hours, address, help"
      />
      <TitleContainer data-cy="contactUsTitle" title="Contact Us" />
      <div className="mx-auto mt-4 max-w-xl">
        <div className="flex flex-row justify-between rounded-lg border border-blue p-4 font-hvMedium shadow-md shadow-blue/30">
          <div className="flex flex-row">
            <IconPhoneFilled />
            <h2>
              <A
                className="!font-hvMedium hover:!text-black"
                href="tel:1-877-649-5463"
                color="black"
                underline="active"
              >
                1-877-649-5463
              </A>
            </h2>
          </div>
          <div>Monday-Friday | 9:30am-8pm ET</div>
        </div>
        <div className="mx-2 mt-6">
          <Formik
            initialValues={initialValues}
            onSubmit={values => {
              onSubmit(values);
            }}
            validationSchema={formSchema}
            enableReinitialize
          >
            {formikProps => (
              <Form className="space-y-0.5">
                {redirect && <Redirect to={`${contactUsPath}/success`} />}
                <FormikFieldWrapper
                  name="formType"
                  data-cy="formType"
                  componentType="combobox"
                  onChange={(selectedOption: Option) => setFormType(selectedOption.key)}
                  options={options}
                  labelComponent="Subject"
                  wrapperClassName="mb-4"
                />
                <FormikFieldWrapper
                  inPlaceError
                  name="userInput.name"
                  data-cy="userInput.name"
                  componentType="input"
                  labelComponent="Full Name"
                  required
                />
                <FormikFieldWrapper
                  inPlaceError
                  name="userInput.email"
                  data-cy="userInput.email"
                  type="email"
                  componentType="input"
                  labelComponent="Email"
                  required
                />
                <FormikFieldWrapper
                  inPlaceError
                  name="userInput.phoneNumber"
                  data-cy="userInput.phoneNumber"
                  componentType="input"
                  labelComponent="Phone Number"
                  required
                />
                {form}
                <div className="sm:translate-0 -translate-x-8 scale-75 transform sm:scale-100 sm:transform-none sm:py-4">
                  <Captcha
                    verifyCallback={response => {
                      formikProps.setFieldValue('recaptchaResponse', response);
                    }}
                  />
                  <InPlaceMessage
                    messageType="error"
                    message={formikProps.errors.recaptchaResponse}
                    touched={formikProps.touched.recaptchaResponse}
                  />
                </div>
                <Button
                  dataCy="submitContactUsForm"
                  color="orange"
                  type="submit"
                  className="!mb-5"
                  disabled={formikProps.isSubmitting}
                >
                  Submit request
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUsPage;
