import { FormikFieldWrapper } from 'app/styleguide/components/Formik';
import * as React from 'react';

const GeneralRequestForm = () => (
  <FormikFieldWrapper
    data-cy="generalRequestComments"
    name="generalRequest.comments"
    rows={5}
    componentType="textarea"
    labelComponent="Comments"
  />
);

export default GeneralRequestForm;
